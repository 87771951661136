import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { ApiError, ApiResponse, Model } from "../models/ApiResponse";
import { Token } from "../components/AuthProvider";

export const callExternalApi = async <T extends Model> (options: {
  config: AxiosRequestConfig;
}): Promise<ApiResponse<T>> => {
  try {
    const response: AxiosResponse = await axios(options.config);
    const { data } = response;


    return {
      data: (data as T),
      error: null,
    };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;

      const { response } = axiosError;

      let retError = {code:"", description:""};

      if (response && response.statusText) {
        retError = {code: response.status.toString(), description: response.statusText};
      }

      if (axiosError.message) {
        console.log(axiosError)
        retError = {code: axiosError.code || "", description: axiosError.message};
      }

      if (response && response.data && (response.data as ApiError).code) {
        retError = response.data as ApiError;
      }

      console.log(retError);

      return {
        data: null,
        error: retError
      };
    }

    return {
      data: null,
      error: {
        description: (error as Error).message,
        code:"XXX"
      },
    };
  }
};


export const callExternalApi_get = async <T extends Model> (uri: string, accessToken: Token): Promise<ApiResponse<T>> => {
  const config: AxiosRequestConfig = {
    method: "GET",
    url: `${process.env.REACT_APP_API_SERVER_URL}/${uri}`,
    headers : {
      "Content-Type": "application/json",
      "Authorization": `${accessToken.token} ${accessToken.username} ${accessToken.timestamp} ${accessToken.nonce}`
    }
  }

  return (await callExternalApi({ config })) as ApiResponse<T>;
}

export const callExternalApi_get_cancellable = async <T extends Model> (uri: string, accessToken: Token, cancelToken: {signal: any}): Promise<ApiResponse<T>> => {
  const config: AxiosRequestConfig = {
    method: "GET",
    url: `${process.env.REACT_APP_API_SERVER_URL}/${uri}`,
    headers : {
      "Content-Type": "application/json",
      "Authorization": `${accessToken.token} ${accessToken.username} ${accessToken.timestamp} ${accessToken.nonce}`
    },
    signal: cancelToken.signal
  }

  return (await callExternalApi({ config })) as ApiResponse<T>;
}

export const callExternalApi_post = async <T extends Model> (uri: string, data:any, accessToken: Token): Promise<ApiResponse<T>> => {
  const config: AxiosRequestConfig = {
    method: "POST",
    url: `${process.env.REACT_APP_API_SERVER_URL}/${uri}`,
    headers : {
      "Content-Type": "application/json",
      "Authorization": `${accessToken.token} ${accessToken.username} ${accessToken.timestamp} ${accessToken.nonce}`
    },
    data
  }

  return (await callExternalApi({ config })) as ApiResponse<T>;
}

export const callExternalApi_get_noauth = async <T extends Model> (uri: string): Promise<ApiResponse<T>> => {
  const config: AxiosRequestConfig = {
    method: "GET",
    url: `${process.env.REACT_APP_API_SERVER_URL}/${uri}`,
    headers : {
      "Content-Type": "application/json",
    },
    decompress: true
  }

  return (await callExternalApi({ config })) as ApiResponse<T>;
}

export const callExternalApi_post_noauth = async <T extends Model> (uri: string, data:any): Promise<ApiResponse<T>> => {
  const config: AxiosRequestConfig = {
    method: "POST",
    url: `${process.env.REACT_APP_API_SERVER_URL}/${uri}`,
    headers : {
      "Content-Type": "application/json",
    },
    data
  }

  return (await callExternalApi({ config })) as ApiResponse<T>;
}

export const callExternalApi_post_noauth_image = async <T extends Model> (uri: string, data:any): Promise<ApiResponse<T>> => {
  const config: AxiosRequestConfig = {
    method: "POST",
    url: `${process.env.REACT_APP_API_SERVER_URL}/${uri}`,
    headers : {
      "Content-Type": "application/json",
    },
    data
  }

  return (await callExternalApi({ config })) as ApiResponse<T>;
}