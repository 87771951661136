import React, { useEffect } from "react";
import { DatasetViewOptions } from "../../models/DatasetViewOptions";
import { api_post_map } from "../../services/data-api.service";
import { useAuth } from "../AuthProvider";
import { usePopup } from "../ErrorPopup/ErrorPopupProvider";

interface PrintModalProps {
    bbox: string;
    displayData: {[key: string]: DatasetViewOptions};
}

export function PrintModal ({bbox, displayData}: PrintModalProps) {

    const [mapImage, setMapImage] = React.useState<string | null>(null);

    const { getToken, validateToken } = useAuth();
    const { showMessage } = usePopup();

    useEffect(() => {
        // Post to server to get map image
        const token = getToken();
        if (!token || !validateToken) return

        api_post_map(bbox, displayData, token)
            .then((response) => {
                console.log("Got map image", response);
                if (response.error) {
                    showMessage({
                        message: `Error getting map image: ${response.error.description}`
                    })
                    return
                }
                if (response.data && response.data.image) {
                    setMapImage(response.data.image);
                }
            })
            .catch((error) => {
                console.error("Error getting map image", error);
                showMessage({
                    message: `Error getting map image: ${error}`
                })
            });
    }, []);



    return  (
        <div>
            <h1>Print map</h1>
            <img className={"print-modal-image"} src={`data:image/png; base64, ${mapImage}`}/>
        </div>
    );

}