import React, { useEffect, useMemo } from "react";
import { DatasetViewOptions, LayeredDatasetViewOptions } from "../../models/DatasetViewOptions";
import { useMap, GeoJSON } from "react-leaflet";
import L from "leaflet";
import { interpolate, interpolateRgb } from "d3-interpolate";
import { DatasetList, FetchedDataset, FetchedDatasetList, LayeredDataset } from "../../models/Datasets";
import { MapFetchFunction, fetchDatasets } from "../../services/map-api.service";
import { useAuth } from "../AuthProvider";
import { visibleLayers } from "./mapfunctions";

interface MapControllerProps {
    viewOptions: { [id: string]: DatasetViewOptions }
    availableDatasets: DatasetList;
    printMode: boolean;

    bbox: string;
    setBbox: (bbox: string) => void;

    setCurrentFeature: (feature: any) => void;
    errorOnLoad: (id: (string | [string, string[]])[], error: any, update?: any) => void;

    source?: MapFetchFunction;

}

function MapController({
    viewOptions,
    setCurrentFeature,
    errorOnLoad,
    bbox,
    setBbox,
    availableDatasets,
    printMode,
    source = fetchDatasets
}: MapControllerProps) {

    const map = useMap();
    const [fetchedData, setFetchedData] = React.useState<FetchedDatasetList>({});
    const [abortController, setAbortController] = React.useState<AbortController | null>(null);

    const { getToken, validateToken } = useAuth();

    useEffect(() => {

        const onMove = () => {
            const bounds = map.getBounds();
            const bbox = `${bounds.getWest().toFixed(5)},${bounds.getSouth().toFixed(5)},${bounds.getEast().toFixed(5)},${bounds.getNorth().toFixed(5)}`
            setBbox(bbox);
        }

        map.on('moveend', onMove)
        onMove()
        return () => {
            map.off('moveend', onMove)
        }
    }, [map])




    useEffect(() => {

        type ToFetchList = { [id: string]: string[] }

        if (!bbox) return
        const token = getToken()

        if (!token || !validateToken()) return

        const toFetch: ToFetchList = Object.keys(viewOptions).filter((dataset) =>
            viewOptions[dataset].enabled && (!fetchedData[dataset] || fetchedData[dataset].loaded_bbox !== bbox)
        ).map((dataset) => {
            if (availableDatasets[dataset].typ === "layered") {
                const layeredDataset = availableDatasets[dataset] as LayeredDataset
                const layeredViewOptions = viewOptions[dataset] as LayeredDatasetViewOptions
                const enabledLayers = Object.keys(layeredDataset.layers).filter((layer) => layeredViewOptions.layerOptions ? layeredViewOptions.layerOptions[layer].enabled : false)
                if (enabledLayers.length > 0)
                    return { [dataset]: enabledLayers }
                else return {}
            }
            else return ({ [dataset]: [] })
        }).reduce((a, b) => ({ ...a, ...b }), {} as ToFetchList)

        console.log(`to_fetch: ${Object.keys(toFetch).join(",")}`)

        if (Object.keys(toFetch).length > 0) {
            // abortController && abortController.abort()
            const newAbortController = new AbortController()
            setAbortController(newAbortController)

            source(
                toFetch,
                availableDatasets,
                bbox,
                map.getZoom(),
                token,
                newAbortController
            ).then((newLoadedData) => {
                if (newLoadedData.error) {
                    errorOnLoad(Object.keys(toFetch), newLoadedData.error)
                    return
                }
                console.log("New loaded data", newLoadedData)
                setFetchedData(ld => {
                    return {
                        ...ld,
                        ...(newLoadedData as FetchedDatasetList)
                    }
                })
            })

        }

    }, [viewOptions, bbox, fetchedData]);

    useEffect(() => {
        if (printMode) {
            map.removeControl(map.zoomControl)
        } else {
            map.addControl(map.zoomControl)
        }
    }, [printMode])

    const popupgenerator = (dataset: FetchedDataset) => (feature: any, layer: L.Layer) => {
        layer.on('click', function (e) {
            setCurrentFeature(feature)
        })
    }

    const stylefunction = (dataset: FetchedDataset) => {

        const baseStyle = (() => {
            if (dataset.childOf) {
                const parentViewOptions = viewOptions[dataset.childOf] as LayeredDatasetViewOptions
                console.log("Parent view options", parentViewOptions)
                if (parentViewOptions && parentViewOptions.layerOptions) {
                    const layerViewOptions = parentViewOptions.layerOptions[dataset.id]
                    if (layerViewOptions) {
                        console.log("Layer view options", layerViewOptions)
                        return { ...layerViewOptions }
                    }
                }
            } else return { ...viewOptions[dataset.id] }
        })() || {}

        console.log("Dataset", dataset)
        console.log("Style", baseStyle)

        return (feature: any) => {
            let fillColor: string = baseStyle.fillColor || "black"
            let opacity: number = baseStyle.fillOpacity === undefined ? 1 : baseStyle.fillOpacity
            
            // console.log("Z Category column", style.categoryColumn, feature.properties[style.categoryColumn || ""], style)
            if (baseStyle.fillColumn && feature.properties[baseStyle.fillColumn]) {
                console.log("Fill column", baseStyle.fillColumn, feature.properties[baseStyle.fillColumn])
                const value = (feature.properties[baseStyle.fillColumn] - (baseStyle.fromValue || 0)) / ((baseStyle.toValue || 1) - (baseStyle.fromValue || 0))
                fillColor = interpolateRgb(baseStyle.fromColor || "black", baseStyle.toColor || "white")(value)
                const frO = baseStyle.fromOpacity === undefined ? 1 : baseStyle.fromOpacity
                const toO = baseStyle.toOpacity === undefined ? 1 : baseStyle.toOpacity
                opacity = interpolate(frO, toO)(value)
            } else if (baseStyle.categoryColumn && baseStyle.categoryColours && feature.properties[baseStyle.categoryColumn]) {
                console.log("Category column", baseStyle.categoryColumn, feature.properties[baseStyle.categoryColumn])
                const value = feature.properties[baseStyle.categoryColumn]
                fillColor = baseStyle.categoryColours[value] || "black"
                console.log(value, fillColor, baseStyle.categoryColours[value])
            }

            const result = { ...baseStyle, fillColor: fillColor, fillOpacity: opacity }
            console.log("Base style", baseStyle)
            console.log("Style result", result)
            return result
            
        }
    }

    function processLayer (dataset: FetchedDataset, prefix: string = ""): JSX.Element[]{
        const geojson = dataset.data;
        const layerStyle = stylefunction(dataset)

        const pointToLayer = (feature: any, latlng: L.LatLng) => {
            const style = layerStyle(feature)
            // @ts-ignore
            const marker = L.circle(latlng, {...style, radius:style.radius})
            marker.setRadius(style.radius || 10)
            popupgenerator(dataset)(feature, marker)
            return marker
        }

        if (dataset.dataset.typ === "layered") {
            const layers = Object.keys(geojson).map((layerID) => {
                const layer = geojson[layerID] as FetchedDataset
                return processLayer(layer, `${prefix}_${layerID}`)
            })
            // Flatten
            return layers.flat()
        } else if (dataset.dataset.geotyp === "point") {
            return [<GeoJSON key={`${prefix}${dataset.id}`} data={geojson} pointToLayer={pointToLayer} />]
        } else {
            return [<GeoJSON key={`${prefix}${dataset.id}`} data={geojson} style={layerStyle} onEachFeature={popupgenerator(dataset)} pointToLayer={pointToLayer} />];
        }

    }


    return (

        <div>
            {visibleLayers(
                fetchedData, availableDatasets, viewOptions, bbox, errorOnLoad
            ).map((dataset) => {
                return processLayer(dataset)
            }).flat()}
        </div>

        // <div>
        //     {visibleLayers(
        //         fetchedData, availableDatasets, viewOptions, bbox, errorOnLoad
        //     ).map((dataset) => {
        //         const geojson = dataset.data;
        //         const sf = stylefunction(dataset)
        //         const pointToLayer = (feature: any, latlng: L.LatLng) => {

        //             const style = sf(feature)
        //             console.log("Feature Style", style)
        //             const marker = L.circleMarker(latlng, {...style, radius:style.radius})
        //             console.log("Radius", style.radius)
        //             marker.setRadius(style.radius || 10)

        //             // const marker = L.circle(latlng, { ...sf(feature) })

        //             popupgenerator(dataset)(feature, marker)
        //             return marker
        //         }
        //         if (dataset.dataset.typ === "layered") {
        //             return Object.keys(geojson).map((layerID) => {
        //                 const layer = geojson[layerID] as FetchedDataset
        //                 return <GeoJSON key={dataset.id + "_" + layer.id} data={layer.data} style={stylefunction(layer)} onEachFeature={popupgenerator(layer)} pointToLayer={pointToLayer} />
        //             })
        //         } else if (dataset.dataset.geotyp === "point") {
        //             return [<GeoJSON key={dataset.id} data={geojson} pointToLayer={pointToLayer} />]
        //         } else
        //             return [<GeoJSON key={dataset.id} data={geojson} style={stylefunction(dataset)} onEachFeature={popupgenerator(dataset)} pointToLayer={pointToLayer} />];
        //     }).flat()}
        // </div>
    )

}

export default MapController;