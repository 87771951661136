import React from "react";

function FeatureInfo ({currentFeature, close} : {currentFeature: any | undefined, close: () => void}) {

    React.useEffect(() => {
        console.log("Feature info updated", currentFeature)
    }, [currentFeature])

    return (
        <div className={currentFeature ? "feature-info" : "feature-info-closed"}>
            {currentFeature ? <button className="feature-info-close" onClick={close}>Close</button> : <span></span>}
            {currentFeature ? (
                    Object.keys(currentFeature.properties).map((key) => {
                        return (
                            <div className="feature-info-item" key={key}>
                                <div className="feature-info-item-key">{key}</div>
                                <div className="feature-info-item-value">{currentFeature.properties[key] != null && currentFeature.properties[key].toString()}</div>
                            </div>
                        )
                    })
                ) : (
                    <div className="no-feature"></div>
                )
            }
            </div>
    )

}

export default FeatureInfo;