import { Token } from "../components/AuthProvider"
import { DatasetList, FetchedDataset, FetchedLayeredDataset, GeojsonFeature, LayeredDataset, LayeredGeojsonFeature } from "../models/Datasets"
import { api_get_data, api_get_mrp_data } from "./data-api.service"


function processDataset (
    feature: GeojsonFeature,
    availableDatasets: DatasetList,
    bbox: string,
): FetchedDataset {

    const fetchedDataset = {
        id: feature.id as string,
        data: feature.data,
        loaded_bbox: bbox,
        dataset: availableDatasets[feature.id],
        childOf: feature.childOf ? feature.childOf as string : undefined
    }

    console.log(`Loaded ${feature.id}`, feature, fetchedDataset)

    if (availableDatasets[feature.id].typ == "layered") {

        const layeredFeature = feature as LayeredGeojsonFeature
        const data = layeredFeature.data

        const layeredFetchedDataset = {
            ...fetchedDataset,
            data: Object.keys(data).map(
                (layer) => processDataset(layeredFeature.data[layer], (availableDatasets[feature.id] as LayeredDataset).layers, bbox)
            )
        }
        return layeredFetchedDataset 
    } else {
        return fetchedDataset
    }

}

export type MapFetchFunction = (
    datasets: {[id: string] : string[]},
    availableDatasets: DatasetList,
    bbox: string,
    zoom: number,
    token: Token,
    abortController: AbortController
) => Promise<{ [id: string]: FetchedDataset } | {error: string, key: string}>

export const fetchDatasets: MapFetchFunction = async (
    datasets: {[id: string] : string[]},
    availableDatasets: DatasetList,
    bbox: string,
    zoom: number,
    token: Token,
    abortController: AbortController
) => {

    const fetchString = Object.keys(datasets).map((datasetID) => {
        if (availableDatasets[datasetID].typ == "layered") {
            console.log(datasetID, datasets[datasetID])
            return `${datasetID}-${datasets[datasetID].join("-")}`
        } else return datasetID
    }).join(",")

    console.log("Fetch string", fetchString)

    const { data, error } = await api_get_data(fetchString, bbox, zoom, token, abortController)
    
    if (data) {
        console.log("Raw data", data)

        return data.features.map((feature) => 
            processDataset(feature, availableDatasets, bbox)
        ).reduce((acc, dataset) => {
            acc[dataset.id] = dataset
            return acc
        }, {} as { [id: string]: FetchedDataset })
    
    }
    else {
        console.error(error)
        return {error: error?.description || error?.code || "Error", key: fetchString}
    }
}

export const fetchMRPDatasets: MapFetchFunction = async (
    datasets: {[id: string] : string[]},
    availableDatasets: DatasetList,
    bbox: string,
    zoom: number,
    token: Token,
    abortController: AbortController
) => {
    const { data, error } = await api_get_mrp_data(bbox, zoom, token, abortController)
    
    if (data) {
        console.log("Raw data", data)

        return data.features.map((feature) => 
            processDataset(feature, availableDatasets, bbox)
        ).reduce((acc, dataset) => {
            acc[dataset.id] = dataset
            return acc
        }, {} as { [id: string]: FetchedDataset })
    
    }
    else {
        console.error(error)
        return {error: error?.description || error?.code || "Error", key: error?.code || "Error"}
    }
}