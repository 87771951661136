import React from "react";

interface Popup {
    message: string;
    color?: string;
    action?: () => void;
    actionText?: string;
}

interface PopupContextProps {
    messages: Popup[];
    showMessage: (message: Popup) => (() => void);
    hideMessage: (idx: Popup) => void;
}

export const PopupContext = React.createContext<PopupContextProps | undefined>(undefined);

interface PopupProviderProps {
    children: React.ReactNode;
}

export function PopupProvider(props: PopupProviderProps) {
    
    const [messages, setMessages] = React.useState<Popup[]>([]);

    const showMessage = (message: Popup) => {
        setMessages([...messages, message]);
        return () => hideMessage(message);
    }

    const hideMessage = (message: Popup) => {
        setMessages(messages.filter((m) => m !== message));
    }

    return (
        <PopupContext.Provider value={{messages, showMessage, hideMessage}}>
            {props.children}
        </PopupContext.Provider>
    )

}

export function usePopup(): PopupContextProps {

    const context = React.useContext(PopupContext);

    if (context === undefined) {
        throw new Error("usePopup must be used within a PopupProvider");
    }

    return context;

}
