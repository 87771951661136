import { Token } from "../components/AuthProvider";
import { ImageModel } from "../models/ApiResponse";
import { DatasetViewOptions } from "../models/DatasetViewOptions";
import { LoadedDatasetList, GeojsonModel } from "../models/Datasets";
import { Election, ElectionResult } from "../models/MRP";
import { callExternalApi_get, callExternalApi_get_cancellable, callExternalApi_post } from "./external-api.service";

export const api_get_data = async (dataset: string, bbox: string, zoom:number, token: Token, abortController: AbortController) => 
    callExternalApi_get_cancellable<GeojsonModel>(`data/${dataset}/${bbox}/${zoom}`, token, abortController)

export const api_get_data_available = async (token: Token) =>
    callExternalApi_get<LoadedDatasetList>(`data`, token)  

export const api_post_map = async (bbox: string, config: {[id: string] : DatasetViewOptions}, token: Token) =>
    callExternalApi_post<ImageModel>(`print/${bbox}`, config, token)

export const api_get_mrp_data = async (bbox: string, zoom:number, token: Token, abortController: AbortController) =>
    callExternalApi_get_cancellable<GeojsonModel>(`mrp/data/${bbox}/${zoom}`, token, abortController)

export const api_get_mrp_data_available = async (token: Token) =>
    callExternalApi_get<LoadedDatasetList>(`mrp/data`, token)

export const api_get_mrp_constituency_data = async (cid: number, token: Token) =>
    callExternalApi_get<ElectionResult[]>(`mrp/constituency/${cid}`, token)

export const api_get_mrp_elections_available = async (token: Token) =>
    callExternalApi_get<Election[]>(`mrp/elections`, token)

export const api_get_mrp_results = async (eid: number, token: Token) =>
    callExternalApi_get<ElectionResult[]>(`mrp/results/${eid}`, token)