import React, { useEffect } from "react";
import { MapContainer, TileLayer} from "react-leaflet";
import 'leaflet/dist/leaflet.css';
import { api_get_data_available } from "../services/data-api.service";
import MapController from "../components/Map/MapController";
import { AvailableDatasetsOptions } from "../components/DatasetController/AvailableDatasetOptions";
import FeatureInfo from "../components/Map/FeatureInfo";
import { usePopup } from "../components/ErrorPopup/ErrorPopupProvider";
import { DatasetViewOptions, LayeredDatasetViewOptions, constructViewOptions, decodeViewOptionsFromUrl, defaultViewOptions, minifyViewOptions, unminifyViewOptions } from "../models/DatasetViewOptions";
import { flushSync } from "react-dom";
import { useModal } from "../components/Modal/ModalProvider";
import { PrintModal } from "../components/Map/PrintModal";
import "../style/MapPage.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera, faClose, faFolderOpen, faLayerGroup, faPrint, faSave, faShare, faShareNodes, faTrash } from "@fortawesome/free-solid-svg-icons";
import { DatasetList } from "../models/Datasets";
import { useAuth } from "../components/AuthProvider";
import logo from "../images/ya_logo_text_tight.png";
import { SaveModal } from "../components/Map/SaveModal";
import { OpenModal } from "../components/Map/OpenModal";
import { SavedMap } from "../models/SavedMap";

function MapPage() {

    const [availableDatasets, setAvailableDatasets] = React.useState<DatasetList | null>(null);
    const [viewOptions, setViewOptions] = React.useState<{ [id: string]: DatasetViewOptions } | undefined>(undefined);

    const [showLayersPanel, setShowLayersPanel] = React.useState<boolean>(true);
    const [currentFeature, setCurrentFeature] = React.useState<any>(undefined);
    const [printMode, setPrintMode] = React.useState<boolean>(false);

    const [bbox, setBbox] = React.useState<string>("");

    const { showMessage } = usePopup();
    const { showModal, hideModal } = useModal();
    const { getToken, validateToken, hasPermission } = useAuth();

    const updateViewOptions = (id: string) => (options: DatasetViewOptions | LayeredDatasetViewOptions) => {
        if (!viewOptions) return
        const thisCurrentOptions = viewOptions[id] || {}
        const thisNewOptions = { ...thisCurrentOptions, ...options }
        const newViewOptions = { ...viewOptions, [id]: thisNewOptions }
        setViewOptions(newViewOptions)
    }

    const errorOnLoad = (id: (string | [string, string[]])[], error: any, update: any = { enabled: false }) => {
        console.error(`Error on one of ${id.join(",")} : ${error}`)
        showMessage({
            message: `Error on one of ${id.join(",")} : ${error}`
        })

        id.forEach(element => {
            if (!viewOptions) return
            if (element instanceof Array) {
                const [id, layers] = element
                const parentNewOptions = { layerOptions: { ...(viewOptions[id] as LayeredDatasetViewOptions).layerOptions } }
                if (parentNewOptions.layerOptions === undefined) {
                    parentNewOptions.layerOptions = {}
                }

                layers.forEach((layer) => {
                    const childCurrentOptions = parentNewOptions.layerOptions[layer] || {}
                    parentNewOptions.layerOptions[layer] = { ...childCurrentOptions, ...update }
                })

                updateViewOptions(id)(parentNewOptions)
            } else {
                updateViewOptions(element)(update)
            }
        });
    }


    const resetViewOptions = (changes?: {[id: string]: DatasetViewOptions}) => {
        if (availableDatasets && Object.keys(availableDatasets).length > 0) {
            const urlOptions = decodeViewOptionsFromUrl()

            const initialViewOptions = Object.keys(availableDatasets).map((dataset) => {
                return constructViewOptions(dataset, urlOptions, changes || {}, availableDatasets)
            }).reduce((a, b) => ({ ...a, ...b }), {} as { [id: string]: DatasetViewOptions })

            setViewOptions(initialViewOptions)
        }
    }

    // Initial load
    useEffect(() => {
        console.log("Available data changed", availableDatasets)
        resetViewOptions()
    }, [availableDatasets])


    // Load list of datasets
    useEffect(() => {
        const get_available_data = async () => {
            console.log("Getting available data")
            const token = getToken()
            if (!token || !validateToken()) return
            const { data, error } = await api_get_data_available(token)

            if (data) {
                const loadedDatasets = Object.keys(data.available).filter((key) => data.loaded.includes(key)).reduce((a, b) => {
                    return { ...a, [b]: data.available[b] }
                }, {} as DatasetList)
                flushSync(() => { setAvailableDatasets(loadedDatasets) })
                console.log("Loaded", data)
            }

            else {
                console.error(error)
                const close = showMessage({
                    message: `Error loading available datasets: ${error?.description}`,
                    action: () => get_available_data(),
                    actionText: "Retry"
                })
                if (error?.code === "DATA_NOT_LOADED") {
                    setTimeout(() => {
                        close()
                        get_available_data()
                    }, 5000)
                }

            }

        }
        get_available_data()
    }, [])


    const launchShareModal = () => {
        const shareString = minifyViewOptions(viewOptions || {})
        const url = `${process.env.REACT_APP_BASE_URL}/map?v=${encodeURIComponent(shareString)}`
        showModal({
            children:
                <div>
                    <h1>Share map</h1>
                    <a href={url}>Link to map</a>
                </div>,
            onClose: () => { }
        })
    }

    const launchPrintModal = () => {
        if (!viewOptions) return
        showModal({
            children: <PrintModal displayData={viewOptions} bbox={bbox} />,
            onClose: () => { }
        })
    }

    const launchSaveModal = () => {
        if (!viewOptions) return
        const enabledLayers = Object.keys(viewOptions).filter((dataset) => viewOptions[dataset].enabled)
        if (enabledLayers.length === 0) {
            showMessage({
                message: `No layers enabled`
            })
            return
        }

        showModal ({
            children: <SaveModal shareString={minifyViewOptions(viewOptions)} enabledLayers={enabledLayers}/>,
            onClose: () => {}
        })
    }

    const launchOpenModal = () => {
        
        const openMap = (map: SavedMap) => {
            const vos = unminifyViewOptions(map.settings)
            console.log("Unminified", vos)
            resetViewOptions(vos)
            hideModal()
        }

        showModal({
            children: <OpenModal openMap={openMap}/>,
            onClose: () => { }
        })
    }



    return (

        <div className="map-page-content">
            <div className="map-page-left">
                <div className="map-panel">
                    <div className="map-container">
                        <MapContainer center={[51.505, -0.09]} zoom={14} minZoom={5} maxZoom={14} scrollWheelZoom={false} id="map">
                            {viewOptions && <MapController
                                viewOptions={viewOptions}
                                setCurrentFeature={setCurrentFeature}
                                errorOnLoad={errorOnLoad}
                                bbox={bbox}
                                setBbox={setBbox}
                                availableDatasets={availableDatasets || {}}
                                printMode={printMode}
                            />}
                            <TileLayer
                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                        </MapContainer>
                    </div>
                    {currentFeature &&
                        <div className="map-panel-right">
                            <div className="feature-info">
                                <FeatureInfo currentFeature={currentFeature} close={() => setCurrentFeature(undefined)} />
                            </div>
                        </div>

                    }
                </div>
            </div>
            {!printMode ?
                <div className="map-share">
                    <button onClick={launchShareModal}><FontAwesomeIcon icon={faShareNodes} /></button>
                    <button onClick={launchPrintModal}><FontAwesomeIcon icon={faPrint} /></button>
                    <button onClick={() => setShowLayersPanel(!showLayersPanel)}>
                        <FontAwesomeIcon icon={faLayerGroup} />
                    </button>
                    <button onClick={() => {
                        setPrintMode(true)
                        setShowLayersPanel(false)
                        setCurrentFeature(undefined)
                    }}><FontAwesomeIcon icon={faCamera}></FontAwesomeIcon></button>
                    <button onClick={() => resetViewOptions()}><FontAwesomeIcon icon={faTrash}></FontAwesomeIcon></button>
                    <button onClick={launchSaveModal}><FontAwesomeIcon icon={faSave}></FontAwesomeIcon></button>
                    <button onClick={launchOpenModal}><FontAwesomeIcon icon={faFolderOpen}></FontAwesomeIcon></button>
                </div> :
                <div className="map-share map-share-small">
                    <button onClick={() => { setPrintMode(false); setShowLayersPanel(true) }}><FontAwesomeIcon icon={faClose}></FontAwesomeIcon></button>
                </div>
            }

            {showLayersPanel && availableDatasets &&
                <div className="map-overlay-right">
                    <div className="map-overlay-right-header">
                        <span>Layers</span>
                        <div className="map-overlay-right-close" onClick={() => setShowLayersPanel(false)}>
                            <FontAwesomeIcon icon={faClose} />
                        </div>
                    </div>
                    {availableDatasets && viewOptions ?
                        <AvailableDatasetsOptions
                            availableDatasets={availableDatasets}
                            viewOptions={viewOptions}
                            updateViewOptions={updateViewOptions}
                        /> :
                        <div>Loading...</div>
                    }

                </div>
            }

            {!showLayersPanel && !currentFeature &&
                <div className="map-overlay-logo">
                    <img src={logo} alt="YIMBY Alliance logo" />
                </div>
            }

        </div>
    );

}

export default MapPage;