import React from "react";
import "../../style/Modal.css"
import { useModal } from "./ModalProvider";

export function Modal() {

    const { modal, hideModal } = useModal();

    console.log("modals", modal);

    return (
        <div className={`modal-container ${modal ? "" : "display-none"}`}>
            {modal &&
                <div className={`modal`}>
                    <div className="modal-content">
                        <div className="modal-message">
                            {modal.children}
                        </div>
                        <div className="modal-buttons">
                            <div className="modal-close" onClick={() => hideModal()}>
                                Close
                            </div>
                        </div>
                    </div>    
                </div>   
            }
        </div>
    )
}
        
