import { Token } from "../components/AuthProvider";
import { callExternalApi_get_noauth, callExternalApi_post_noauth } from "./external-api.service";

export const api_authenticate = async (username: string, password: string) => {
    const timestamp = new Date().getTime()

    return callExternalApi_post_noauth<Token>(`authenticate`, {
        username:username,
        password: password,
        timestamp: timestamp,
    })
}

export const api_auth_checktoken = async (token: Token) => {
    
}