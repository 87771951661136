import { FetchedDataset, FetchedDatasetList, DatasetList, LayeredDataset } from "../../models/Datasets";
import { DatasetViewOptions, LayeredDatasetViewOptions } from "../../models/DatasetViewOptions";


export function getFilterFunction(dataset: FetchedDataset, viewOptions: DatasetViewOptions) {

    let error: string | undefined = undefined;
    const op = viewOptions.filterOperator || "==";

    const v: string = viewOptions.filterValue || "";
    let n_v: number | undefined = undefined;

    const column = viewOptions.filterColumn || "";
    const numericOp = op === "<" || op === ">" || op === "<=" || op === ">=";

    if (numericOp) {
        n_v = parseFloat(v);
        if (isNaN(n_v)) {
            error = `Numeric operator ${op} used but filter value '${v}' not a number`;
        }
    }

    console.log(`Filtering ${dataset.id} with ${column} ${op} ${v}`);

    const ff = (feature: any) => {
        if (error)
            return false;
        let value = feature.properties[column];
        if (value === undefined) {
            //TODO: error popup
            return false;
        }

        if (numericOp) {
            const n_value = parseFloat(value);
            if (isNaN(value)) {
                error = `Numeric operator ${op} used but '${column}' contains value ${value}`;
                return false;
            }
            value = n_value;
        }

        if (numericOp) {
            // TODO: Replace with a proper parser
            return eval(`${value} ${op} ${v}`);
        }
        else if (op === "==" || op === "!=") {
            return (op === "==" ? value === v : value !== v);
        }
        else if (op === "in" || op === "not in") {
            return (op === "in" ? value.includes(v) : !value.includes(v));
        }
        else {
            error = `Invalid filter operator ${op}`;
            return false;
        }
    };

    return { ff, error };
}

export function visibleLayers(
    fetchedData: FetchedDatasetList,
    availableDatasets: DatasetList,
    viewOptions: { [id: string]: DatasetViewOptions; },
    bbox: string,
    errorOnLoad: (id: (string | [string, string[]])[], error: any, update?: any) => void
): FetchedDataset[] {
    console.log("Fetching visible layers", fetchedData, availableDatasets, viewOptions, bbox);
    return Object.values(fetchedData)
        .filter((dataset) => {
            return dataset.loaded_bbox === bbox && viewOptions[dataset.id] && viewOptions[dataset.id].enabled;
        })
        .sort((a, b) => (viewOptions[a.id].z_index ?? 0) - (viewOptions[b.id].z_index ?? 0))
        .map((fetchedDataset) => {
            if (availableDatasets[fetchedDataset.id].typ === "layered") {
                return {
                    ...fetchedDataset,
                    data: visibleLayers(
                        fetchedDataset.data,
                        (availableDatasets[fetchedDataset.id] as LayeredDataset).layers,
                        (viewOptions[fetchedDataset.id] as LayeredDatasetViewOptions).layerOptions || {},
                        bbox,
                        //TODO: type unsafe - fix
                        (ids, error, update) => errorOnLoad([[fetchedDataset.id, ids as string[]]], error, update)
                    )
                };
            }
            else if (viewOptions[fetchedDataset.id].filterColumn) {

                const { ff, error } = getFilterFunction(fetchedDataset, viewOptions[fetchedDataset.id]);

                const res = { ...fetchedDataset, data: { ...fetchedDataset.data, features: fetchedDataset.data.features.filter(ff) } };

                if (error) {
                    console.error(error);
                    errorOnLoad([fetchedDataset.id], error, { filterOperator: "==" });

                }

                return res;

            }
            else {
                return fetchedDataset;
            }

        });
}
