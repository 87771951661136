import React from "react";
import "../../style/Popup.css"
import { usePopup } from "./ErrorPopupProvider";

export function ErrorPopup() {

    const { messages, hideMessage } = usePopup();

    return (
        <div className={`error-popup-container ${messages.length > 0 ? "" : "display-none"}`}>
            {messages.map((message, idx) => 
                <div className={`error-popup ${message.color ? 'error-popup-' + message.color : ''}`} key={idx}>
                    <div className="error-popup-content">
                        <div className="error-popup-message">
                            {message.message}
                        </div>
                        <div className="error-popup-buttons">
                            {message.action && message.actionText &&
                                <div className="error-popup-action" onClick={() => {
                                    hideMessage(message)
                                    message.action && message.action()
                                }}>
                                    {message.actionText}
                                </div>
                            }
                            <div className="error-popup-close" onClick={() => hideMessage(message)}>
                                Close
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )


}