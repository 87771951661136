import React, { useCallback, useEffect } from "react";
import mapboxgl, { Expression } from 'mapbox-gl';   
import 'mapbox-gl/dist/mapbox-gl.css';
import { api_get_mrp_constituency_data, api_get_mrp_elections_available, api_get_mrp_results } from "../services/data-api.service";
import { useAuth } from "../components/AuthProvider";
import { Election, ElectionResult } from "../models/MRP";
import "../style/ConstituencyMapPage.css"
import "../style/MapTopCard.css"
import "../style/MRPPage.css"
import { usePopup } from "../components/ErrorPopup/ErrorPopupProvider";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faCamera, faClose, faShare, faShareNodes } from "@fortawesome/free-solid-svg-icons";
// import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { ConstituencyCard } from "../components/MRP/ConstituencyCard";
import { get } from "http";

mapboxgl.accessToken = "pk.eyJ1IjoiZnJlZGRpZS15aW1ieSIsImEiOiJjbHBjdHJrdTAwcno2MnFrN3NvZjFoanZoIn0.a9zXaiHigQQBu1cgBKPxmg"

const partyColorMatch: Expression = [
    'match',
    ['get', 'first_party'],
    "Con", "#0000FF",
    "Lab", "#FF0000",
    "LD", "#FAA61A",
    "Green", "#00FF00",
    "Brexit", "#12B6CF",
    "SNP", "#FFF95D",
    "PC", "#008142",

    /* other */ "#000"
]

function doAll(fs: (() => void)[]) {
    fs.forEach(f => {
        try {
            f()
        } catch (e) {
            console.error(e)
        }
    })
}

function FontAwesomeIcon(props: any) {
    return <div>FontAwesomeIcon</div>
}

function icon(props: any) {
    return <div>icon</div>
}



export default function MRPPage() {

    const { showMessage } = usePopup();
    const { getToken, validateToken, hasPermission } = useAuth();


    const mapConainer = React.useRef<any>(null);
    const map = React.useRef<mapboxgl.Map | null>(null);
    const [lng, setLng] = React.useState(-3.4);
    const [lat, setLat] = React.useState(54.14);
    const [zoom, setZoom] = React.useState(5.84);

    const [selectedConstituency, setSelectedConstituency] = React.useState<number | null>(null);
    const [selectedConstituencyData, setSelectedConstituencyData] = React.useState<ElectionResult[] | null>(null);

    const [availableElections, setAvailableElections] = React.useState<Election[]>([])
    const [selectedElection, setSelectedElection] = React.useState<Election | null>()
    const [electionResults, setElectionResults] = React.useState<ElectionResult[]>([])

    const [searchQuery, setSearchQuery] = React.useState<string>("")
    const [searchResults, setSearchResults] = React.useState<ElectionResult[]>([])

    const [showShare, setShowShare] = React.useState<boolean>(false)

    const [loaded, setLoaded] = React.useState(false)
    const [cameraMode, setCameraMode] = React.useState<boolean>(false);

    const getAvailableElections = async () => {
        const token = getToken()
        if (!token || !validateToken()) return
        const { data, error } = await api_get_mrp_elections_available(token)

        if (data) {
            console.log("SETTING AE", data)
            setAvailableElections(data)

            // Get any settings from URL
            const urlParams = new URLSearchParams(window.location.search);
            const cid = urlParams.get('cid')
            const eid = urlParams.get('eid')
            const election = eid ? data.find(e => e.eid === Number(eid)) : null
            setSelectedElection(election || data[0])

            if (cid) {
                setSelectedConstituency(Number(cid))
            } 

            

            
        } else {
            console.error(error)
            showMessage({
                message: "Error loading available elections",
                color: "red",
                actionText: "Retry",
                action: () => getAvailableElections()
            })
        }
    }

    const getElectionResults = async () => {
        const token = getToken()
        if (!token || !validateToken()) return
        if (!selectedElection) return
        const { data, error } = await api_get_mrp_results(selectedElection.eid, token)

        if (data) {
            console.log("SETTING ER", data)
            setElectionResults(data)
        } else {
            console.error(error)
            showMessage({
                message: "Error loading election results",
                color: "red",
                actionText: "Retry",
                action: () => getElectionResults()
            })
        }
    }

    useEffect(() => {
        const loadConstituencyResult = async () => {

            const token = getToken()
            
            if (!token || !validateToken()) return
            if (!selectedConstituency || !selectedElection) return
            
            console.log("LOADING CONSTITUENCY DATA", selectedConstituency, selectedElection)
            const { data, error } = await api_get_mrp_constituency_data(selectedConstituency, token)        

            if (data) {
                // const election_result = data?.find((r: ElectionResult) => r.election === selectedElection.eid)
                // if (!election_result) return

                setSelectedConstituencyData(data)

                const offset = window.innerWidth < 1200 ? 0 : 0.62

                map.current?.flyTo({
                    center: [(data[0].c_obj?.centroid[0] || 0)-offset, data[0].c_obj?.centroid[1]  || 0],
                    zoom: 9
                })

            } else {
                console.error(error)
                showMessage({
                    message: "Error loading constituency data",
                    color: "red",
                    actionText: "Retry",
                    action: () => loadConstituencyResult()
                })
            }
        }

        console.log("SELECTED CONSTITUENCY", selectedConstituency)
        
        loadConstituencyResult()
        if (selectedElection && selectedConstituency) {
            map.current?.setFilter('const-highlighted', ['==', 'cid', selectedConstituency || '']);   
        }
    }, [selectedConstituency])
    

    const onClickFunction = useCallback((e: any) => {
        console.log("CLICKED", e)
        const constituency = e.features[0].properties
        setSelectedConstituency(previousCid => {
            let cid: null | number = constituency.cid !== previousCid ? constituency.cid : null
            console.log("Setting selected constituency", cid, constituency)
            setSelectedConstituencyData(null)
            setSelectedConstituency(cid)
            return cid
        })

    }, [])

    const addMapLayers = (selectedElection: Election) => {

        if (map.current == null) return;

        doAll([
            () => map.current?.off('click', 'const', onClickFunction),
            () => map.current?.removeLayer('const'),
            () => map.current?.removeLayer('const-highlighted'),
            () => map.current?.removeSource('const')
        ])
        
        // disable map rotation using right click + drag
        map.current.dragRotate.disable();
        
        // disable map rotation using touch rotation gesture
        map.current.touchZoomRotate.disableRotation();

        map.current.addSource('const', {
            type: 'vector',
            tiles: [`${process.env.REACT_APP_API_SERVER_URL}/mrp/${selectedElection.eid}/mvt/{z}/{x}/{y}.pbf`]
        });

        map.current.addLayer({
            'id': 'const',
            'type': 'fill',
            'source': 'const',
            'source-layer': 'default',
            'paint': {
                'fill-color': partyColorMatch,
                'fill-opacity': 0.3,
                'fill-outline-color': '#000'
            }
        });

        map.current.addLayer({
            'id': 'const-highlighted',
            'type': 'fill',
            'source': 'const',
            'source-layer': 'default',
            'paint': {
                'fill-color': partyColorMatch,
                'fill-opacity': 1,
                'fill-outline-color': '#000'
            },
            'filter': ['==', 'cid', '']
        })

        map.current.on('click', 'const', onClickFunction)

    }

    const onMapLoad = () => {
        
    }

    React.useEffect(() => {

        let added = false;

        const doAdd = () => {
            if (added) return
            if (!loaded || !selectedElection) setTimeout(doAdd, 1000);
            added = true;
            setSelectedConstituency(null)
            getElectionResults()
            addMapLayers(selectedElection!)
        }


        if (!selectedElection) return;
        if (!loaded) setTimeout(doAdd, 1000);

        // if (!loaded) {
        //     setLoaded(true)
        // } else {
        //     setSelectedConstituency(null)
        // }
        // getElectionResults()
        // addMapLayers(selectedElection)

    }, [selectedElection])


    // Initialise map and load data
    React.useEffect(() => {
        if (map.current) return; // initialize map only once
        map.current = new mapboxgl.Map({
            container: mapConainer.current,
            style: 'mapbox://styles/mapbox/streets-v12',
            center: [lng, lat],
            zoom: zoom,
        });
        map.current.on('load', onMapLoad);
        map.current.on('move', () => {
            if (map.current) {
                setLng(map.current.getCenter().lng);
                setLat(map.current.getCenter().lat);
                setZoom(map.current.getZoom());
                // console.log("MOVE", map.current.getZoom())
                // console.log("MOVE", map.current.getCenter())

            }
        })

        map.current.on('error', (e) => {
            console.error(e)

            if (e.tile) {
                showMessage({
                    message: "Error loading map",
                    color: "red",
                    actionText: "Retry",
                    action: () => window.location.reload()
                })
            }
        })

        getAvailableElections()

    }, []);

    useEffect(() => {
        
        if (!availableElections) return
        if (!electionResults) return
        if (!searchQuery) return setSearchResults([])

        const results = electionResults.filter(c => c.c_obj?.constituency_name.toLowerCase().includes(searchQuery.toLowerCase()) || (c.mp_firstname + " " + c.mp_surname).toLowerCase().includes(searchQuery.toLowerCase()))

        setSearchResults(results)

    }, [searchQuery])
    
    const showConstituencyData = selectedConstituency 
        && selectedConstituencyData 
        // && (selectedConstituencyData.constituency == selectedConstituency)

    return (
        <div className="mrp-page-content">
            <div className="full-map-container">
                <div ref={mapConainer} id="map"></div>                        
            </div>
            {cameraMode ? 
            <div className="map-top-cards-container map-top-cards-container-thin">
                <div className="map-top-card">
                    <button className="map-top-card-button" onClick={() => setCameraMode(false)}>
                        <FontAwesomeIcon icon={icon({name: "close"})}/>
                    </button>
                </div>
            </div> : 
            <div className="map-top-cards-container">
                <div className="map-top-card map-top-card-overlay" style={{zIndex:2003}}>
                    <div className="map-top-card-controls">
                        {availableElections && <select value={selectedElection?.eid} onChange={(e) => {
                                const eid = e.target.value
                                const election = availableElections.find(e => e.eid === Number(eid))
                                console.log("SELECTED ELECTION", election)
                                setSelectedElection(election || null)
                            }}>
                                {availableElections.map(e => <option key={e.eid} value={e.eid}>{e.election_name}</option>)}
                            </select>
                        }
                        <div className="map-top-card-search-controls">
                            <div className="map-top-card-search">
                                <div className="map-top-card-search-pseudo-input">
                                    <input type="text" placeholder="Search for a constituency" className="map-top-card-search-input" value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} />
                                    <button className="map-top-card-search-clear-button" onClick={() => setSearchQuery("")}>
                                        <FontAwesomeIcon icon={icon({name: "close"})}/>
                                    </button>
                                </div>
                                <div className="map-top-card-search-suggestions-container">
                                    <div className="map-top-card-search-suggestions">
                                        {searchResults.map((r, i) => <div key={i} className="map-top-card-search-suggestion" onClick={() => {
                                            console.log("SETTING", r.constituency, r)
                                            setSelectedConstituency(r.constituency)
                                            setSearchQuery("")
                                            setSearchResults([])
                                        }}>{r.c_obj?.constituency_name} ({r.mp_firstname} {r.mp_surname})</div>)}
                                    </div>
                                </div>                            
                            </div>
                        </div>
                        <div className="map-top-card-button-controls">
                            <button className={`map-top-card-button ${!selectedConstituency && "map-top-card-button-grey"}`} onClick={() => {setShowShare(selectedConstituency ? !showShare : false)}}>
                                <FontAwesomeIcon icon={icon({name: "share-nodes"})}/>
                            </button>
                            <button className="map-top-card-button" onClick={() => setCameraMode(true)}>
                                <FontAwesomeIcon icon={icon({name: "camera"})}/>
                            </button>
                        </div>
                    </div>
                </div>
                {showShare &&<div className="map-top-card" style={{zIndex:2002}}>
                    <div className="map-top-card-share">
                         <input type="text" value={`${window.location.origin}/mrp?cid=${selectedConstituency}&eid=${selectedElection?.eid}`} readOnly/>
                         <div className="map-top-card-button-controls">
                            <button className="map-top-card-button" onClick={async () => {
                                try {
                                    await navigator.clipboard.writeText(`${window.location.origin}/mrp?cid=${selectedConstituency}&eid=${selectedElection?.eid}`)
                                    showMessage({
                                        message: "Copied to clipboard",
                                        color: "green"
                                    })
                                }
                                catch (err) {
                                    console.error('Failed to copy: ', err);
                                    showMessage({
                                        message: "Error copying to clipboard",
                                        color: "red"
                                    })
                                }
                                
                            }} ><FontAwesomeIcon icon={icon({name:"clipboard", style:"regular"})} ></FontAwesomeIcon></button>
                            <button onClick={() => setShowShare(false)} className="map-top-card-button">
                                <FontAwesomeIcon icon={icon({name: "close"})}/>
                            </button>
                        </div>
                    </div>
                </div>}
                {showConstituencyData && <div className="map-top-card map-top-card-large" style={{zIndex:2001}}>
                    <div className="map-top-card-content">
                        <ConstituencyCard 
                            results={selectedConstituencyData}
                            close={() => setSelectedConstituency(null)}
                            primaryElection={selectedElection?.eid || 0}
                            secondaryElection={availableElections[1].eid}
                            elections={availableElections}
                        />
                    </div>
                </div>}   
            </div>}
        </div>
    )
}
