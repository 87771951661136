import React, { useEffect } from "react";
import { DatasetViewOptions, defaultViewOptions } from "../../models/DatasetViewOptions";
import { LayeredDatasetControllerProps } from "./AvailableDatasetOptions";
import { DatasetController } from "./DatasetController";


export function LayeredDatasetController({ id, viewOptions, dataset, updateViewOptions, simplified }: LayeredDatasetControllerProps) {

    const [collapsed, setCollapsed] = React.useState<boolean>(false)

    const updateChildViewOptions = (layer: string) => (options: DatasetViewOptions) => {
        if (!viewOptions.layerOptions) return
        const thisCurrentOptions = viewOptions.layerOptions[layer] || {}
        const thisNewOptions = { ...thisCurrentOptions, ...options }
        const newViewOptions = { ...viewOptions, layerOptions: { ...viewOptions.layerOptions, [layer]: thisNewOptions } }
        updateViewOptions(newViewOptions)
    }

    console.log("Layered dataset controller", dataset, viewOptions.layerOptions)

    useEffect(() => {
        if (viewOptions.enabled && !viewOptions.layerOptions) {
            const initialLayerOptions = Object.keys(dataset.layers).map((layer) => {
                return {
                    [layer]: {
                        ...defaultViewOptions(),
                        ...(dataset.defaultViewOptions || {}),
                    }
                }
            }).reduce((a, b) => ({ ...a, ...b }), {} as { [id: string]: DatasetViewOptions })
            updateViewOptions({ layerOptions: initialLayerOptions })
        }
    }, [viewOptions, dataset])
    


    return (
        <div className={`dataset-controller ${viewOptions.enabled && "dataset-controller-enabled dataset-controller-enabled-layered"}`}>
            <div className="dataset-controller-header">
                <div className="dataset-controller-name">{dataset.name}</div>
                {viewOptions.enabled && <div className="dataset-controller-collapse" onClick={() => setCollapsed(!collapsed)}>
                    {collapsed ? "▶" : "▼"}</div>}
            </div>
            <div className="dataset-controller-controls dataset-controller-controls-layered">
                <div className="dataset-controller-enable">
                    <label>Enable</label>
                    <input type="checkbox" checked={viewOptions.enabled} onChange={(e) => updateViewOptions({ enabled: e.target.checked })} />
                    <label>Z index</label>
                    <input type="number" value={viewOptions.z_index} onChange={(e) => updateViewOptions({ z_index: parseInt(e.target.value) })} />
                </div>
                {viewOptions.enabled && !collapsed && viewOptions.layerOptions !== undefined &&
                    Object.keys(dataset.layers).map((layer) => {
                        return <DatasetController
                            id={layer}
                            key={layer}
                            // @ts-ignore
                            viewOptions={viewOptions.layerOptions[layer]}
                            dataset={dataset.layers[layer]}
                            updateViewOptions={updateChildViewOptions(layer)}
                            childOf={id}
                            simplified={simplified}
                        />
                        })}
            </div>
        </div>

    );

}
