import { Token } from "../components/AuthProvider";
import { SavedMap } from "../models/SavedMap";
import { callExternalApi_get, callExternalApi_post } from "./external-api.service";

export const api_save_map = async (title: string, description: string, settings: string, enabledLayers:string, token: Token) => 
    callExternalApi_post<{success:boolean}>(
        `saved/save`,
        {
            title: title,
            description: description,
            settings: settings,
            enabledLayers: enabledLayers
        },
        token
    )

export const api_get_saved_maps = async (token: Token) =>
    callExternalApi_get<{success:boolean, savedMaps: Array<SavedMap>}>(
        `saved/get`,
        token
    )

export const api_delete_saved_map = async (id: string, token: Token) =>
    callExternalApi_post<{success:boolean}>(
        `saved/delete/${id}`,
        {},
        token
    )