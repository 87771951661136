import React, { useEffect } from "react";
import { api_delete_saved_map, api_get_saved_maps } from "../../services/save-api.service";
import { useAuth } from "../AuthProvider";
import { usePopup } from "../ErrorPopup/ErrorPopupProvider";
import { SavedMap } from "../../models/SavedMap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolderOpen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useModal } from "../Modal/ModalProvider";



export interface OpenModalProps {
    openMap: (map: SavedMap) => void;
}

export function OpenModal ( {openMap} : OpenModalProps) {
    
    const [savedMaps, setSavedMaps] = React.useState<SavedMap[]>([]);

    const { getToken } = useAuth();
    const { showMessage } = usePopup();

    const deleteMap = (map: SavedMap) => {
        const token = getToken();
        if (!token) return
        api_delete_saved_map(map.id, token).then((response) => {
            console.log("Delete map response", response);
            if (response.error) {
                console.error("Error deleting map", response.error);
                showMessage({
                    message: `Error deleting map: ${response.error.description}`
                })
            } else if (response.data?.success) {
                showMessage({
                    message: `Map deleted successfully`,
                    color: "green"
                })
                setSavedMaps(savedMaps.filter((m) => m.id !== map.id));
            }
        })
    }


    useEffect(() => {

        const token = getToken();
        if (!token) return

        api_get_saved_maps(token).then((response) => {
            console.log("Got saved maps", response);
            if (response.error) {
                showMessage({
                    message: `Error getting saved maps: ${response.error.description}`
                })
                return
            }
            if (response.data && response.data.savedMaps) {
                setSavedMaps(response.data.savedMaps);
            }
        })
    }, [])

    return (
        <div>
            <h1>Open map</h1>
            <div className="open-modal-list">
                {savedMaps.map((map) => {
                    return (
                        <div key={map.id} className="open-modal-map" onClick={() => openMap(map)}>
                            <div className="open-modal-map-header">
                                <b>{map.title}</b>
                                <button className="open-modal-map-delete" onClick={(e) => {
                                    deleteMap(map)
                                    e.stopPropagation();
                                }}>
                                    <FontAwesomeIcon icon={faTrash}/>
                                </button>
                            </div>
                            
                            <div>{map.description || "-"}</div>
                            <div className="open-modal-map-layers"><b>Layers enabled:</b> {map.enabledLayers}</div>
                            
                        </div>
                    )
                })}
            </div>
        </div>
    )
}