import React, { useEffect } from "react";
import { useAuth } from "./AuthProvider";
import { usePopup } from "./ErrorPopup/ErrorPopupProvider";
import logo from "../images/ya_logo_text_tight.png";
import { Link } from "react-router-dom";

type PageProps = {
    children: React.ReactNode;
    pageTitle?: string;
    requireAuth?: boolean;
    requirePermissions?: string[];
}

function Page({ children, pageTitle, requireAuth, requirePermissions }: PageProps) {

    const { login, validateToken, hasPermission, logout } = useAuth();

    console.log("Page", pageTitle, requireAuth, validateToken());

    useEffect(() => {
        if (pageTitle) {
            document.title = pageTitle;
        }
    }, [pageTitle]);

    if (!validateToken()) {
        return (
            <div className="page">
                <LoginPage completeLogin={() => window.location.reload()} />
            </div>
        );
    } else if (requirePermissions && !requirePermissions.every(p => hasPermission(p))) {
        return (
            <div className="page">
                <div className="page-login">
                    <h1>Access denied</h1>
                    <p>You do not have permission to access this page.</p>
                    <p>
                        <a href="#" onClick={() => logout()}>Logout</a> | <Link to="/">Home</Link>
                    </p>
                </div>
            </div>
        );
    } else {
        return (
            <div className="page">
                {children}
            </div>
        );
    }



}

function LoginPage({ completeLogin }: { completeLogin: () => void }) {

    const { login } = useAuth();
    const { showMessage } = usePopup();

    const doLogin = async (username: string, password: string) => {
        const [success, message] = await login(username, password);
        if (!success) {
            showMessage({ message });
        }
        else {
            completeLogin();
        }
    }

    return (
        <div className="page-login">

            <div className="login-logo">
                <img src={logo} />
            </div>
            <h1>Log in</h1>
            <form onSubmit={(e) => {
                e.preventDefault();
                const username = (e.target as any).username.value;
                const password = (e.target as any).password.value;
                doLogin(username, password);
            }
            }>
                <div className="login-input-group">
                    <label>Username: </label>
                    <input type="text" name="username" />
                </div>
                <div className="login-input-group">
                    <label>Password: </label>
                    <input type="password" name="password" />
                </div>
                <input type="submit" value="Log in" />


            </form>
        </div>
    );


}

export default Page;