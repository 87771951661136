import React from "react"
import { DatasetControllerProps } from "./AvailableDatasetOptions"

export function DatasetController({ id, viewOptions, dataset, updateViewOptions, childOf, simplified }: DatasetControllerProps) {

    const [collapsed, setCollapsed] = React.useState<boolean>(true)

    console.log("Dataset controller", id, viewOptions, dataset, updateViewOptions, childOf, simplified)
    console.log("Available category columns", viewOptions.availableCategoryColumns)

    const changeCategoryColumn = (column: string) => {

        if (!column)
            updateViewOptions({
                categoryColumn: "",
                categoryColours: undefined
            })

        if (!viewOptions.availableCategoryColumns || !viewOptions.availableCategoryColumns[column] || !viewOptions.initialCategoryColours) return



        updateViewOptions({
            categoryColumn: column,
            fillColumn: "",
            categoryColours: viewOptions.initialCategoryColours[column]
        })
    }


    return (
        <div className={`dataset-controller ${viewOptions.enabled && "dataset-controller-enabled"} ${childOf && "dataset-controller-child"}`}>
            <div className="dataset-controller-header">
                <div className="dataset-controller-name">{dataset.name}</div>
                {viewOptions.enabled && <div className="dataset-controller-collapse" onClick={() => setCollapsed(!collapsed)}>
                    {collapsed ? "▶" : "▼"}</div>}
            </div>
            <div className="dataset-controller-controls">
                <div className="dataset-controller-enable">
                    <label>Enable</label>
                    <input type="checkbox" checked={viewOptions.enabled} onChange={(e) => updateViewOptions({ enabled: e.target.checked })} />
                    {!simplified && <>
                        <label>Z index</label>
                        <input type="number" value={viewOptions.z_index} onChange={(e) => updateViewOptions({ z_index: parseInt(e.target.value) })} />
                    </>}
                </div>

                {viewOptions.enabled && !collapsed && <span>
                    <div className="dataset-controller-control-group dataset-controller-line">
                        <div className="dataset-controller-control">
                            <label>Line Opacity</label>
                            <input type="range" min={0} max={1} step={0.01} value={viewOptions.opacity} onChange={(e) => updateViewOptions({ opacity: parseFloat(e.target.value) })} />
                        </div>
                        <div className="dataset-controller-control">
                            <label>Line Weight</label>
                            <input type="range" min={0} max={5} step={0.01} value={viewOptions.weight} onChange={(e) => updateViewOptions({ weight: parseFloat(e.target.value) })} />
                        </div>
                        <div className="dataset-controller-control">
                            <label>Color</label>
                            <input type="color" value={viewOptions.color} onChange={(e) => updateViewOptions({ color: e.target.value })} />
                        </div>
                        {dataset.geotyp === "point" &&
                            <div className="dataset-controller-control">
                                <label>Radius ({viewOptions.radius} m)</label>
                                <input type="range" min={1} max={5000} step={1} value={viewOptions.radius} onChange={(e) => updateViewOptions({ radius: parseFloat(e.target.value) })} />
                            </div>
                        }

                    </div>
                    {dataset.geotyp !== "line" && <>
                        {!simplified && <>
                        <div className="dataset-controller-control-group dataset-controller-fill">
                            <div className="dataset-controller-control">
                                <label>Fill</label>
                                <input type="checkbox" checked={viewOptions.fill} onChange={(e) => updateViewOptions({ fill: e.target.checked })} />
                            </div>
                            <div className="dataset-controller-control">
                                <label>Fill Opacity</label>
                                <input type="range" min={0.001} max={1} step={0.01} value={viewOptions.fillOpacity} onChange={(e) => updateViewOptions({ fillOpacity: parseFloat(e.target.value) })} />
                            </div>

                            <div className="dataset-controller-control">
                                <label>Fill Color</label>
                                <input type="color" value={viewOptions.fillColor} onChange={(e) => updateViewOptions({ fillColor: e.target.value })} />
                            </div>
                        </div>
                        </>}

                        <div className="dataset-controller-control-group dataset-controller-fill-column">
                            <div className="dataset-controller-control">
                                <label>Fill Column</label>
                                <select value={viewOptions.fillColumn} onChange={(e) => updateViewOptions({ fillColumn: e.target.value })}>
                                    <option value={""}>None</option>
                                    {dataset.columns.map((column) => {
                                        return <option value={column} key={column}>{column}</option>
                                    })}
                                </select>
                            </div>
                            {viewOptions.fillColumn && (
                                <>
                                    <div className="dataset-controller-control">
                                        <label>From Color</label>
                                        <input type="color" value={viewOptions.fromColor} onChange={(e) => updateViewOptions({ fromColor: e.target.value })} />
                                    </div>
                                    <div className="dataset-controller-control">
                                        <label>To Color</label>
                                        <input type="color" value={viewOptions.toColor} onChange={(e) => updateViewOptions({ toColor: e.target.value })} />
                                    </div>
                                    <div className="dataset-controller-control">
                                        <label>From Value</label>
                                        <input type="number" value={viewOptions.fromValue} onChange={(e) => updateViewOptions({ fromValue: parseFloat(e.target.value) })} />
                                    </div>
                                    <div className="dataset-controller-control">
                                        <label>To Value</label>
                                        <input type="number" value={viewOptions.toValue} onChange={(e) => updateViewOptions({ toValue: parseFloat(e.target.value) })} />
                                    </div>
                                    <div className="dataset-controller-control">
                                        <label>From Opacity</label>
                                        <input type="range" min={0} max={1} step={0.01} value={viewOptions.fromOpacity} onChange={(e) => updateViewOptions({ fromOpacity: parseFloat(e.target.value) })} />
                                    </div>
                                    <div className="dataset-controller-control">
                                        <label>To Opacity</label>
                                        <input type="range" min={0} max={1} step={0.01} value={viewOptions.toOpacity} onChange={(e) => updateViewOptions({ toOpacity: parseFloat(e.target.value) })} />
                                    </div>

                                </>
                            )}
                        </div>

                        {viewOptions.availableCategoryColumns && <div className="dataset-controller-control-group dataset-controller-fill-column">
                            <div className="dataset-controller-control">
                                <label>Category column</label>
                                <select value={viewOptions.categoryColumn} onChange={(e) => changeCategoryColumn(e.target.value)}>
                                    <option value={""}>None</option>
                                    {Object.keys(viewOptions.availableCategoryColumns).map((column) => {
                                        return <option value={column} key={column}>{column}</option>
                                    })}
                                </select>
                            </div>
                            {viewOptions.categoryColumn && (
                                <>
                                    <div>
                                        {viewOptions.availableCategoryColumns &&
                                            viewOptions.availableCategoryColumns[viewOptions.categoryColumn] &&
                                            viewOptions.availableCategoryColumns[viewOptions.categoryColumn].map((column) => {
                                        return <option value={column} key={column}>{column}</option>
                                    })}

                                    </div>

                                </>
                            )}
                        </div>}



                    </>}

                    <div className="dataset-controller-control-group">
                        <div className="dataset-controller-control">
                            <label>Filter</label>
                            <select value={viewOptions.filterColumn} onChange={(e) => updateViewOptions({ filterColumn: e.target.value })}>
                                <option value={""}>None</option>
                                {dataset.columns.map((column) => {
                                    return <option value={column} key={column}>{column}</option>
                                })}
                            </select>
                        </div>
                        {viewOptions.filterColumn && (
                            <>
                                <div className="dataset-controller-control">
                                    <label>Filter value</label>
                                    <input value={viewOptions.filterValue} onChange={(e) => updateViewOptions({ filterValue: e.target.value })} />
                                </div>
                                <div className="dataset-controller-control">
                                    <label>Operator</label>
                                    <select value={viewOptions.filterOperator} onChange={(e) => updateViewOptions({ filterOperator: e.target.value })}>
                                        <option value={"=="}>=</option>
                                        <option value={"!="}>!=</option>
                                        <option value={">"}>&gt;</option>
                                        <option value={"<"}>&lt;</option>
                                        <option value={">="}>&gt;=</option>
                                        <option value={"<="}>&lt;=</option>
                                        <option value={"in"}>contains</option>
                                        <option value={"not in"}>not contains</option>
                                    </select>
                                </div>
                            </>
                        )}
                    </div>
                </span>}
            </div>
        </div >
    )

}