import React from "react";
import { DatasetViewOptions, LayeredDatasetViewOptions } from "../../models/DatasetViewOptions";
import "../../style/DatasetController.css";
import { Dataset, LoadedDatasetList, LayeredDataset, DatasetList } from "../../models/Datasets";
import { LayeredDatasetController } from "./LayeredDatasetController";
import { DatasetController } from "./DatasetController";
import { flushSync } from "react-dom";

interface AvailableDatasetOptionsProps {
    updateViewOptions: (dataset: string) => (viewOptions: DatasetViewOptions) => void;
    viewOptions: { [id: string]: DatasetViewOptions };
    availableDatasets: DatasetList;
    simplified?: boolean;
}

export interface DatasetControllerProps {
    id: string;
    key: string;
    viewOptions: DatasetViewOptions;
    dataset: Dataset;
    updateViewOptions: (viewOptions: DatasetViewOptions | LayeredDatasetViewOptions) => void;
    childOf?: string;
    simplified?: boolean;
}

export interface LayeredDatasetControllerProps extends DatasetControllerProps {
    dataset: LayeredDataset;
    viewOptions: LayeredDatasetViewOptions;
}


export function AvailableDatasetsOptions({ updateViewOptions, viewOptions, availableDatasets, simplified }: AvailableDatasetOptionsProps) {

    console.log("Available datasets options", availableDatasets, viewOptions)
    
    return (
        <div className="dataset-controller-list">
            {
                availableDatasets && viewOptions && Object.keys(availableDatasets).map((key) => {
                    const dataset = availableDatasets[key]

                    const controllerProps = {
                        id: key,
                        key: key,
                        dataset: dataset,
                        viewOptions: viewOptions[key],
                        updateViewOptions: updateViewOptions(key),
                        simplified: simplified
                    }

                    return (
                        dataset.typ === "layered" ?
                            <LayeredDatasetController {...(controllerProps as LayeredDatasetControllerProps)} key={key} /> :
                            <DatasetController {...controllerProps} key={key} />
                    );

                })
            }
        </div>
    )

}