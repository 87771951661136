export interface ElectionResult {
    erid: number;
    constituency: number;
    election: number;
    first_party: string;
    second_party: string;
    valid_votes: number;
    electorate: number;
    mp_firstname: string;
    mp_surname: string;
    con: number;
    lab: number;
    ld: number;
    dup: number;
    green: number;
    other: number;
    brexit: number;
    pc: number;
    snp: number;
    sf: number;
    uup: number;
    alliance: number;
    sdlp: number;

    c_obj?: Constituency;
    e_obj?: Election;

}

export interface Constituency {
    cid: number;
    constituency_name: string;
    boundary_review: number;

    centroid: [number, number];

    br_obj?: BoundaryReview;
}

export interface BoundaryReview {
    brid: number;
    year: number;
}

export interface Election {
    eid: number;
    election_year: number;
    election_type: string;
    election_name: string;
    boundary_review: number;
    
    br_obj?: BoundaryReview;
}

export interface ElectionResultDictionary {
    [key: string]: number[];
}

export function electionResultToShareDictionary (er: ElectionResult, parties?: string[]): ElectionResultDictionary {

    const obj = {
        con : [er.con, er.con / er.valid_votes],
        lab : [er.lab, er.lab / er.valid_votes],
        ld : [er.ld, er.ld / er.valid_votes],
        dup : [er.dup, er.dup / er.valid_votes],
        green : [er.green, er.green / er.valid_votes],
        other : [er.other, er.other / er.valid_votes],
        brexit : [er.brexit, er.brexit / er.valid_votes],
        pc : [er.pc, er.pc / er.valid_votes],
        snp : [er.snp, er.snp / er.valid_votes],
        sf : [er.sf, er.sf / er.valid_votes],
        uup : [er.uup, er.uup / er.valid_votes],
        alliance : [er.alliance, er.alliance / er.valid_votes],
    }

    return parties ?
        Object.fromEntries(
            Object.entries(obj).filter(([k, _]) => parties.includes(k))
        ) :
        Object.fromEntries(
            Object.entries(obj).filter(([_, v]) => v[0] > 0)
        )
}

// export function electionResultToSortedParties(er: ElectionResult, parties? : string[]): [string, number][] {
//     const dict = electionResultToShareDictionary(er, parties);
//     return Object.entries(dict).sort((a, b) => parties? parties.indexOf(a[0]) - parties.indexOf(b[0]) : b[1] - a[1]);
// }

export function getParySort(result: ElectionResultDictionary, parties: string[]) : string[] {
    return parties.sort((a, b) => (result[b] && result[a]) ? result[b][0] - result[a][0] : 0);    
}

export function getPartiesInElectionResults(ers: ElectionResult[]): string[] {
    const parties = ers.map(er => Object.keys(electionResultToShareDictionary(er)));
    return Array.from(new Set(parties.flat()));
}

export const PartyNameMap: {[id: string]: string} = {
    "con": "Conservative",
    "lab": "Labour",
    "ld":  "Liberal Democrat",
    "dup": "DUP",
    "green": "Green",
    "other": "Other",
    "brexit": "Brexit",
    "pc": "Plaid Cymru",
    "snp": "SNP",
    "sf": "Sinn Fein",
    "uup": "UUP",
    "alliance": "Alliance",
}