import React from "react";
import { useAuth } from "../AuthProvider";
import { api_save_map } from "../../services/save-api.service";
import { usePopup } from "../ErrorPopup/ErrorPopupProvider";
import { useModal } from "../Modal/ModalProvider";

export interface SaveModalProps {
    shareString: string;
    enabledLayers: string[];
}

export function SaveModal ({shareString, enabledLayers}: SaveModalProps) {

    const { getToken } = useAuth();
    const { showMessage } = usePopup();
    const { hideModal } = useModal();

    const save = (title: string, description: string) => {
        const token = getToken();
        if (!token) return

        console.log("Save map", title, description, shareString);
        api_save_map(title, description, shareString, enabledLayers.join(","), token).then((response) => {
            console.log("Save map response", response);
            if (response.error) {
                console.error("Error saving map", response.error);
                showMessage({
                    message: `Error saving map: ${response.error.description}`
                })
            } else if (response.data?.success) {
                showMessage({
                    message: `Map saved successfully`,
                    color: "green"
                })
                hideModal();
            }
        })

    }

    return (
        <div className="save-modal">
            <h1>Save map</h1>
            <form onSubmit={(e) => {
                e.preventDefault();
                const title = (e.target as any).title.value;
                const description = (e.target as any).description.value;
                save(title, description);
            }}>
                <label>Title</label>
                <input type="text" name="title"/>
                <label>Description</label>
                <textarea name="description"/>
                <input type="submit" value="Save"/>
            </form>
            <div>
                <h3>Layers enabled</h3>
                <ul>
                    {enabledLayers.map((layer) => <li key={layer}>{layer}</li>)}
                </ul>

            </div>
        </div>
    )

}