import React from "react";
import Page from "../components/Page";
import logo from "../images/ya_logo_text_tight.png";
import { useAuth } from "../components/AuthProvider";

function Home () {

    const { hasPermission, logout, getToken } = useAuth();

    return (
        <Page pageTitle="Home" requireAuth>
            <div className="page-content">
                <h1>YIMBY Alliance Mapping</h1>
                <div className="login-logo">
                    <img src={logo} />
                </div>
                <ul>
                    {hasPermission("map") ? <li><a href="/map">Map</a></li> : null}
                    {hasPermission("mrp") ? <li><a href="/mrp">MRP</a></li> : null}
                </ul>
                <div>
                    User: {getToken()?.username}<br/>
                    <a href="#" onClick={() => logout()}>Logout</a>
                </div>
                
            </div>
        </Page>
    );

}

export default Home;