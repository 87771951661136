import React from "react";
import { Election, ElectionResult, PartyNameMap, electionResultToShareDictionary, getPartiesInElectionResults, getParySort } from "../../models/MRP";
import Chart from "react-google-charts";
import { BarChart, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Bar, ResponsiveContainer, Cell } from "recharts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import "../../style/ConstituencyCard.css"

export interface ConstituencyCardProps {
    results : ElectionResult[]
    elections : Election[]
    primaryElection: number
    secondaryElection?: number
    close : () => void
}


// Primary and muted colours
const partyColourMap: {[id: string]: [string, string]} = {
    "con" : ["#0087DC", "#B3D1F3"],
    "lab" : ["#DC241f", "#F8B4B4"],
    "ld"  : ["#FAA61A", "#FCE6B4"],
    "green" : ["#6AB023", "#C2E0A3"],
    "brexit" : ["#12B6CF", "#B3E7EC"],
    "snp" : ["#FFF95D", "#FFF95D"],
    "pc" : ["#008142", "#008142"],
    "dup" : ["#D46A4C", "#D46A4C"],
    "sf" : ["#326760", "#326760"],
    "sdlp" : ["#99FF66", "#99FF66"],
    "uup" : ["#231F20", "#231F20"],
    "alliance" : ["#F6CB2F", "#F6CB2F"],
    "other" : ["#808080", "#808080"],
}

const electionResultToChartRows = (primary: ElectionResult, secondary?: ElectionResult, parties?: string[], useShares?: boolean) => { 
    return [
        {id: "con", party: "Conservative", primary_res: primary.con, secondary_res: secondary ? secondary.con : 0},
        {id: "lab", party: "Labour", primary_res: primary.lab, secondary_res: secondary ? secondary.lab : 0},
        {id: "ld", party: "Liberal Democrat", primary_res: primary.ld, secondary_res: secondary ? secondary.ld : 0},
        {id: "green", party: "Green", primary_res: primary.green, secondary_res: secondary ? secondary.green : 0},
        {id: "brexit", party: "Brexit Party", primary_res: primary.brexit, secondary_res: secondary ? secondary.brexit : 0},
        {id: "snp", party: "SNP", primary_res: primary.snp, secondary_res: secondary ? secondary.snp : 0},
        {id: "pc", party: "Plaid Cymru", primary_res: primary.pc, secondary_res: secondary ? secondary.pc : 0},
        {id: "dup", party: "DUP", primary_res: primary.dup, secondary_res: secondary ? secondary.dup : 0},
        {id: "sf", party: "Sinn Fein", primary_res: primary.sf, secondary_res: secondary ? secondary.sf : 0},
        {id: "sdlp", party: "SDLP", primary_res: primary.sdlp, secondary_res: secondary ? secondary.sdlp : 0},
        {id: "uup", party: "UUP", primary_res: primary.uup, secondary_res: secondary ? secondary.uup : 0},
        {id: "alliance", party: "Alliance", primary_res: primary.alliance, secondary_res: secondary ? secondary.alliance : 0},
        {id: "other", party: "Other", primary_res: primary.other, secondary_res: secondary ? secondary.other : 0},
    ].filter(
        r => r.primary_res > 0 || r.secondary_res > 0 || (!parties || parties.includes(r.id))
    ).sort(
        (a,b) => parties ? parties.indexOf(a.id) - parties.indexOf(b.id) : b.primary_res - a.primary_res
    ).map(
        r => { 
            return {
                ...r,
                primary_res: useShares ? r.primary_res / primary.valid_votes * 100 : r.primary_res,
                secondary_res: useShares && secondary ? r.secondary_res / secondary?.valid_votes * 100 : r.secondary_res,
            }
        }
    )
}

const PartyTooltip = ({ active, payload, label} : any) => {
    if (active && payload && payload.length) {
        console.log(payload)
        return (
            <div className="chart-custom-tooltip">
                <h4>{label}</h4>
                {payload.map( (a: any) => {
                    return (
                        <p key={a.dataKey} className="chart-tooltip-label">{`${a.name} : ${a.value} ${a.unit}`}</p>
                    )
                })}
                <p></p>
            </div>
        );
    }
    return null;
}

export function ConstituencyCard( { results, elections, close, primaryElection, secondaryElection } : ConstituencyCardProps )  {

    const [useShares, setUseShares] = React.useState<boolean>(false)

    const primaryResult = results.find(r => r.election === primaryElection)
    const secondaryResult = secondaryElection ? results.find(r => r.election === secondaryElection) : undefined
    const constituency = primaryResult?.c_obj
    const resultDictionaries = results.map(r => { return { dict: electionResultToShareDictionary(r), result: r }})

    if (!results || !primaryResult || !constituency) 
        return <div className="constituency-card">
            <div className="constituency-card-header">
                <div>
                    <h1>No results found</h1>
                    
                </div>
                <div>
                    <button className="map-top-card-button" onClick={close}>
                        <FontAwesomeIcon icon={faTimes} />
                    </button>
                </div>
            </div>
        </div>

    if (!primaryResult.c_obj) throw new Error("No constituency object")

    const primaryResultDictionary = electionResultToShareDictionary(primaryResult)
    const parties = getPartiesInElectionResults(results)
    const sortedParties = getParySort(primaryResultDictionary, parties)
    const chart_rows = electionResultToChartRows(primaryResult, secondaryResult, sortedParties, useShares)
    
    return (
        <div className="constituency-card">
            <div className="constituency-card-header">
                <div>
                    <h1>{constituency.constituency_name}</h1>
                    
                </div>
                <div>
                    <button className="map-top-card-button" onClick={close}>
                        <FontAwesomeIcon icon={faTimes} />
                    </button>
                </div>
            </div>
            <h2>{primaryResult.mp_firstname} {primaryResult.mp_surname} ({primaryResult.first_party})</h2>
            <input type="checkbox" checked={useShares} onChange={() => setUseShares(!useShares)} /> Use shares
            <div className="constituency-card-detail">
                <div className="constituency-card-detail-item">
                    <ResponsiveContainer width="100%" height={400}>
                        <BarChart data={chart_rows}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="party" />
                            <YAxis />
                            <Tooltip content={<PartyTooltip />}/>
                            <Legend />
                            <Bar 
                                dataKey="primary_res"
                                name={elections.find(e => e.eid == primaryElection)?.election_name}
                                legendType="none"
                                unit={useShares ? "%" : "Votes"}
                            >
                                {
                                    sortedParties.map(party => <Cell key={party} fill={partyColourMap[party][0]} />)
                                }
                            </Bar>
                            {secondaryElection && 
                                <Bar 
                                    dataKey="secondary_res" 
                                    name={elections.find(e => e.eid == secondaryElection)?.election_name}
                                    legendType="none"
                                    unit={useShares ? "%" : "Votes"}
                                >
                                    {
                                        sortedParties.map(party => <Cell key={party} fill={partyColourMap[party][1]} />)
                                    }
                                </Bar>
                            }
                        </BarChart>
                    </ResponsiveContainer>
                </div>
                <div className="constituency-card-detail-item">

                    <table>
                        <thead>
                            <tr>
                                <th>Election</th>
                                {
                                    sortedParties.map(p => <th key={p}>{PartyNameMap[p]}</th>)
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                elections.map(e => {
                                    const result = resultDictionaries.find(r => r.result.election === e.eid)
                                    if (!result) return null
                                    return (
                                        <tr key={e.eid}>
                                            <td>{e.election_name}</td>
                                            {
                                                sortedParties.map(p => useShares ? 
                                                    <td key={p}>{(result.dict[p] ? result.dict[p][1]*100 : 0).toFixed(1)} %</td> :
                                                    <td key={p}>{(result.dict[p] ? result.dict[p][0] : 0).toLocaleString()}</td>)
                                            }
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>

                </div>
            </div>
            
        </div>
    );
}
