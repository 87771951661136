import React from "react";
import { api_authenticate } from "../services/auth-api.service";

export interface Token {
    token: string;
    username: string;
    timestamp: number;
    nonce: string;
    permissions: string[];
}

interface AuthContextProps {
    validateToken: () => boolean;
    getToken: () => Token | undefined;
    login: (username: string, password: string) => Promise<[boolean, string]>;
    logout: () => void;
    clearToken: () => void;
    hasPermission: (permission: string) => boolean;
}

const TOKEN_TIMEOUT =  6 * 60 * 60 * 1000;

export const AuthContext = React.createContext<AuthContextProps | undefined>(undefined);

export function AuthProvider({ children }: React.PropsWithChildren<{}>) {

    const [token, setToken] = React.useState<Token | undefined>(undefined);

    const getToken: () => (Token | undefined) = () => {
        if (!token) {
            console.log("No state token, checking local storage");
            const storedToken = localStorage.getItem("token");
            if (storedToken) {
                const parsedToken = JSON.parse(storedToken);
                if (parsedToken && parsedToken.timestamp && parsedToken.token && parsedToken.username && parsedToken.nonce && parsedToken.permissions) {
                    setTimeout(() => setToken(parsedToken));
                    return parsedToken;
                }
                else {
                    console.error("Invalid token in local storage", parsedToken)
                    return undefined;
                }
            } else {
                console.log("No token in local storage");
                return undefined;
            }
            
        } else { return token; }
    }

    const validateToken = () => {
       
        let checkToken = getToken();
        if (!checkToken) {
            return false;
        }

        const time_since_last_login = Date.now() - checkToken.timestamp;
        console.log("Time since last login", time_since_last_login);

        if (time_since_last_login >= TOKEN_TIMEOUT) {
            console.log("Token expired");
            return false;
        }

        return true;
    }

    async function login(username: string, password: string): Promise<[boolean, string]> {
        const { data, error } = await api_authenticate(username, password);

        if (error || !data) {
            console.error("Error logging in", error);
            return [false, error?.description || "Error logging in"];
        } else {
            setToken(data);
            localStorage.setItem("token", JSON.stringify(data));
            console.log("Logged in", data);
            return [true, ""];
        }

    }

    const clearToken = () => {
        setToken(undefined);
    }

    const hasPermission = (permission: string) => {
        if (token) {
            return token.permissions.includes(permission)
        } 
        return false
    }

    const logout = () => {
        clearToken();
        localStorage.removeItem("token");
        document.location.reload();
    }

    return (
        <AuthContext.Provider
            value={{
                getToken,
                validateToken,
                login,
                clearToken,
                hasPermission,
                logout
            }}
        >
            {children}
        </AuthContext.Provider>
    )

}

export function useAuth(): AuthContextProps {

    const context = React.useContext(AuthContext);

    if (context === undefined) {
        throw new Error("useAuth must be used within a AuthProvider");
    }

    return context;

}

