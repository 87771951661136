import { DatasetList, LayeredDataset } from "./Datasets";

export interface DatasetViewOptions {
    enabled?: boolean;

    color?: string;
    fillColor?: string;
    fill?: boolean;
    fillOpacity?: number;
    weight?: number;
    opacity?: number;

    fillColumn?: string;
    fromColor?: string;
    toColor?: string;
    fromOpacity?: number;
    toOpacity?: number;
    fromValue?: number;
    toValue?: number;

    z_index?: number;

    filterColumn?: string;
    filterValue?: string;
    filterOperator?: string;

    radius?: number;

    categoryColumn?: string;
    availableCategoryColumns?: {[id: string]: string[]}
    initialCategoryColours?: {[id: string]: {[id: string]: string}}
    categoryColours?: {[id: string]: string}

}

export interface LayeredDatasetViewOptions extends DatasetViewOptions {
    layerOptions?: { [id: string]: DatasetViewOptions }    
}

const mappings: any = {
    "enabled" : "e",
    "color" : "c",
    "fillColor" : "fc",
    "fill" : "f",
    "fillOpacity" : "fo",
    "weight" : "w",
    "opacity" : "o",

    "fillColumn" : "fcm",
    "fromColor" : "frmcol",
    "toColor" : "tocol",
    "fromOpacity" : "frmo",
    "toOpacity" : "too",
    "fromValue" : "frmv",
    "toValue" : "tov",

    "z_index" : "z",

    "filterColumn" : "ftcol",
    "filterValue" : "ftval",
    "filterOperator" : "ftop",

    "radius" : "r",

    "layerOptions" : "lo",

    "categoryColumn" : "cc",
}

function _minifyViewOptions (datasets: {[id: string] : DatasetViewOptions}): any {
    console.log("Minifying view options", datasets)
    const min = Object.keys(datasets).map(key => {
        const dataset = datasets[key]
        if (!dataset.enabled){
            return {}
        }
        
        const min = {...Object.keys(dataset).reduce((a, b) => {
            const key = mappings[b]
            if (key){
                return {...a, [key]: (dataset as any)[b]}
            }
            else {
                return a
            }
        }, {} as any), id: key}
        if ((dataset as LayeredDatasetViewOptions).layerOptions){
            // Layered Dataset
            const layerOptions = (dataset as LayeredDatasetViewOptions).layerOptions
            const minifiedLayerOptions = _minifyViewOptions(layerOptions || {})
            min["lo"] = minifiedLayerOptions
        } 
        return min
    }).filter((ds) => Object.keys(ds).length > 0)
    console.log("Minified", min)
    return min
}

export function minifyViewOptions(datasets: {[id: string] : DatasetViewOptions}): string {
    return JSON.stringify(_minifyViewOptions(datasets))
}

function _unminifyViewOptions (datasets: any): {[id: string]: DatasetViewOptions} {

    console.log("Unminifying view options", datasets)
    const unminified = Object.keys(datasets).map(key => {
        const dataset = datasets[key]
        const options = Object.keys(dataset).reduce((a, b) => {
            const key = Object.keys(mappings).find((k) => mappings[k] === b)
                if (key){
                    if (key === "layerOptions"){
                        console.log("Found layerOptions", dataset[b])
                        const layerOptions = _unminifyViewOptions(dataset[b])
                        return {...a, [key]: layerOptions}
                    } else {
                        return {...a, [key]: (dataset as any)[b]}
                    }
                }
                else {
                    return a
                }
            }, ({} as {[id: string]: DatasetViewOptions}))
        return {[dataset.id]: options}
    }).reduce((a, b) => ({...a, ...b}), {} as {[id: string]: DatasetViewOptions})
    return unminified
}


export function unminifyViewOptions(minified: string) : {[id: string]: DatasetViewOptions} {
    console.log("Unminifying view options", minified)
    return _unminifyViewOptions(JSON.parse(minified))
}

export function decodeViewOptionsFromUrl() {
    const location = window.location.search
    const params = new URLSearchParams(location)
    const shareString = params.get("v")
    if (shareString) {
        const decoded = unminifyViewOptions(shareString)
        console.log("Decoded", decoded)
        return decoded
    }
    else return {}
}

export function constructViewOptions(datasetKey: string, urlOptions: { [id: string]: DatasetViewOptions }, changes: {[id: string]: DatasetViewOptions}, availableDatasets: DatasetList): { [id: string]: DatasetViewOptions } {
    if (!availableDatasets) return {}
    if (availableDatasets[datasetKey].typ === "layered") {
        console.log("Constructing layered", datasetKey, urlOptions[datasetKey], availableDatasets[datasetKey].defaultViewOptions)
        const layeredDataset = availableDatasets[datasetKey] as LayeredDataset
        const layeredViewOptions = (urlOptions[datasetKey] || {}) as LayeredDatasetViewOptions
        const changedViewOptions = (changes[datasetKey] || {}) as LayeredDatasetViewOptions

        const initialLayerOptions = Object.keys(layeredDataset.layers).map((layer) => {
            return {
                [layer]: {
                    ...defaultViewOptions(),
                    ...(layeredDataset.defaultViewOptions || {}),
                    ...(layeredDataset.layers[layer].defaultViewOptions || {}),
                    ...(layeredViewOptions.layerOptions ? layeredViewOptions.layerOptions[layer] : {}),
                    ...(changedViewOptions.layerOptions ? changedViewOptions.layerOptions[layer] : {}),
                }
            }
        }).reduce((a, b) => ({ ...a, ...b }), {} as { [id: string]: DatasetViewOptions })

        return {
            [datasetKey]: {
                ...defaultViewOptions(),
                ...(availableDatasets[datasetKey].defaultViewOptions || {}),
                ...(layeredViewOptions || {}),
                ...(changedViewOptions || {}),
                layerOptions: initialLayerOptions
            } as LayeredDatasetViewOptions
        }

    }
    else {
        console.log("Constructing", datasetKey, urlOptions[datasetKey], availableDatasets[datasetKey].defaultViewOptions)
        return {
            [datasetKey]: {
                ...defaultViewOptions(),
                ...(availableDatasets[datasetKey].defaultViewOptions || {}),
                ...(urlOptions[datasetKey] || {}),
                ...(changes[datasetKey] || {}),
            }
        }
    }

}


  

export const defaultViewOptions: () => DatasetViewOptions = () => {
    return {
        enabled: false,
        color: "#000000",
        fill: true,
        fillOpacity: 0.5,
        weight: 1,
        opacity: 1,
        z_index: 10,
        radius: 10
    }
}

