import React from "react";

interface ModalProps {
    children: React.ReactNode;
    onClose: () => void;
}

interface ModalContextProps {
    modal: ModalProps | undefined;
    showModal: (m: ModalProps) => void;
    hideModal: () => void;
}

interface ModalProviderProps {
    children: React.ReactNode;
}


export const ModalContext = React.createContext<ModalContextProps| undefined>(undefined);

export function ModalProvider(props: ModalProviderProps) {

    const [modal, setModal] = React.useState<ModalProps | undefined>(undefined);

    const showModal = (m: ModalProps) => {
        console.log("showModal", m);
        setModal(m);
    }

    const hideModal = () => {
        setModal(undefined);
    }

    return (
        <ModalContext.Provider value={{modal, showModal, hideModal}}>
            {props.children}
        </ModalContext.Provider>
    )

}

export function useModal(): ModalContextProps {

    const context = React.useContext(ModalContext);

    if (context === undefined) {
        throw new Error("useModal must be used within a ModalProvider");
    }

    return context;

}