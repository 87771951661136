import React from 'react';
import logo from './logo.svg';
import './App.css';
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import Home from './pages/Home';
import MapPage from './pages/MapPage';
import { ErrorPopup } from './components/ErrorPopup/ErrorPopup';
import { PopupProvider } from './components/ErrorPopup/ErrorPopupProvider';
import { ModalProvider } from './components/Modal/ModalProvider';
import { Modal } from './components/Modal/Modal';

import { AuthProvider, useAuth } from './components/AuthProvider';
import Page from './components/Page';
import MRPPage from './pages/MRPPage';

function App() {

  const router = createBrowserRouter(createRoutesFromElements([
    <Route path="/" element={<Home />} />,
    <Route path="/map" element={<Page pageTitle="Map" requireAuth requirePermissions={["map"]}><MapPage /></Page>} />,
    <Route path="/mrp" element={<Page pageTitle="MRP" requireAuth requirePermissions={["mrp"]}><MRPPage/></Page>} />,
  ]));

  return (
    <ModalProvider>
      <PopupProvider>
        <AuthProvider>
          <div className="App">
            <Modal />
            <ErrorPopup />
            <RouterProvider router={router} />
          </div>
        </AuthProvider>
      </PopupProvider>
    </ModalProvider>
  );
}

export default App;
